.all-main-container {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  padding-bottom: 50px;
}

.content-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.video-container {
  max-width: 1150px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  /* padding-top: 90px; */
  z-index: 99;
}
.secondST {
  text-decoration: none;
}

.video-frame {
  width: 95%;
  height: 657px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: none;
  margin-bottom: 30px;
}

.video-frame.visible {
  display: block;
}

.iframe {
  width: 100%;
  height: 100%;
}

.custom-pagination .MuiPaginationItem-root {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(255, 255, 255, 1);
}

.custom-pagination .MuiPaginationItem-root:hover {
  background-color: none;
}

.custom-pagination .MuiPaginationItem-root.Mui-selected {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(47, 150, 253, 1);
  font-size: 20px;
  font-weight: 600;
}

.custom-pagination .MuiPaginationItem-root.Mui-selected:hover {
  background-color: none;
}

.custom-pagination .MuiPaginationItem-previousNext,
.custom-pagination .MuiPaginationItem-ellipsis {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.custom-pagination .MuiPaginationItem-previousNext .MuiSvgIcon-root,
.custom-pagination .MuiPaginationItem-ellipsis .MuiSvgIcon-root {
  font-size: 40px;
  border-radius: 50%;
  color: rgba(47, 150, 253, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.custom-pagination .MuiPaginationItem-previousNext:hover,
.custom-pagination .MuiPaginationItem-ellipsis:hover {
  background-color: none;
}

@media (max-width: 1024px) {
  .iframe {
    width: 95%;
    height: 550px;
    display: block;
    margin: 0 auto;
  }
  .video-frame {
    width: 100%;
    height: 600px;
    display: none;
    background-size: cover;
    background-position: center;
  }
  .vid-thumb {
    width: 240px !important;
    height: 135px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px 20px 0px 0px;
  }

  .secondST {
    position: relative;
    width: 240px !important;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 850px) {
  .iframe {
    width: 95%;
    height: 450px;
    display: block;
    margin: 0 auto;
  }
  .video-frame {
    width: 100%;
    height: 450px;
    display: none;
    background-size: cover;
    background-position: center;
  }
  .vid-thumb {
    width: 200px !important;
    height: 110px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px 20px 0px 0px;
  }

  .secondST {
    position: relative;
    width: 200px !important;
    height: 35px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .secondST-description {
    position: absolute;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 18px !important;
    font-weight: 700 !important;
    right: 5%;
    top: -45% !important;
  }
  .secondST-text {
    position: absolute !important;
    font-size: 13px !important;
    font-weight: 500;
    color: #727272;
    top: -15% !important;
  }
}
@media (max-width: 700px) {
  .iframe {
    width: 95%;
    height: 400px;
    display: block;
    margin: 0 auto;
  }
  .video-frame {
    width: 100%;
    height: 400px;
    display: none;
    background-size: cover;
    background-position: center;
  }
  .vid-thumb {
    width: 160px !important;
    height: 90px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px 20px 0px 0px;
  }

  .secondST {
    position: relative;
    width: 160px !important;
    height: 33px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .secondST-description {
    position: absolute;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 15px !important;
    font-weight: 700 !important;
    right: 5%;
    top: -30% !important;
  }
  .secondST-text {
    position: absolute !important;
    font-size: 10px !important;
    font-weight: 500;
    color: #727272;
    top: 0% !important;
  }

  .logo-img {
    width: 45px !important;
  }

  .title-img {
    height: 30px !important;
  }
  .main-titLogo span {
    font-size: 14px;
    padding-left: 5px;
  }
}

.main-titLogo {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  margin-bottom: 20px;
}

.all-titLogo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-img {
  width: 52px;
}

.title-img {
  height: 40px;
}

.thumbnail-content {
  display: flex;
  flex-direction: column;
  gap: none;
}

.video-thumbnails {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 9px;
}

.vid-thumb {
  width: 260px;
  height: 145px;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 20px 20px 0px 0px;
}

.secondST {
  position: relative;
  width: 260px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.secondST-text {
  font-size: 14px;
  font-weight: 500;
  color: #727272;
}

.secondST-description {
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  right: 5%;
  top: -30%;
}

.student-name {
  font-size: 20px;
}

.Cpag {
  margin-top: 40px;
}

@media (max-width: 500px) {
  .video-frame {
    width: 100% !important;
    height: 307px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    display: none;
    margin-bottom: 30px;
  }
  .iframe {
    width: 100% !important;
    height: 300px !important;
  }

  .video-thumbnails {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }
  .vid-thumb {
    width: 120px !important;
    height: 65px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .secondST {
    position: relative;
    width: 120px !important;
    height: 30px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
  }
  .secondST-description {
    position: absolute;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 12px !important;
    font-weight: 700 !important;
    top: 0% !important;
    right: 7%;
  }
  .secondST-text {
    position: absolute !important;
    font-size: 10px !important;
    font-weight: 500;
    color: #727272;
    top: -30% !important;
  }
  .custom-pagination .MuiPaginationItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(47, 150, 253, 1);
    font-size: 20px !important;
    font-weight: 600;
  }

  .custom-pagination .MuiPaginationItem-previousNext .MuiSvgIcon-root,
  .custom-pagination .MuiPaginationItem-ellipsis .MuiSvgIcon-root {
    font-size: 35px;
    border-radius: 50%;
    color: rgba(47, 150, 253, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .custom-pagination .MuiPaginationItem-previousNext,
  .custom-pagination .MuiPaginationItem-ellipsis {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 20px !important;
  }
  .main-titLogo {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    margin-bottom: 0px !important;
    gap: 0 !important;
  }

  .all-titLogo {
    display: flex;
    align-items: center;
    gap: 5px !important;
  }

  .logo-img {
    width: 52px;
  }

  .title-img {
    height: 40px;
  }
}

@media (max-width: 415px) {
  .video-thumbnails {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }
  .vid-thumb {
    width: 160px !important;
    height: 90px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .secondST {
    position: relative;
    width: 160px !important;
    height: 35px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
  }
  .secondST-description {
    position: absolute;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 16px !important;
    font-weight: 700 !important;
    top: -10% !important;
  }
  .secondST-text {
    position: absolute !important;
    font-size: 14px !important;
    font-weight: 500;
    color: #727272;
    top: -45% !important;
  }
  .custom-pagination .MuiPaginationItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(47, 150, 253, 1);
    font-size: 20px !important;
    font-weight: 600;
  }

  .custom-pagination .MuiPaginationItem-previousNext .MuiSvgIcon-root,
  .custom-pagination .MuiPaginationItem-ellipsis .MuiSvgIcon-root {
    font-size: 30px;
    border-radius: 50%;
    color: rgba(47, 150, 253, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .custom-pagination .MuiPaginationItem-previousNext,
  .custom-pagination .MuiPaginationItem-ellipsis {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .custom-pagination .MuiPaginationItem-root {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(255, 255, 255, 1);
    margin: 0px !important;
  }

  .Cpag {
    margin-top: 20px !important;
  }
  .main-titLogo span {
    font-size: 12px !important;
  }
}

@media (max-width: 400px) {
  .video-frame {
    width: 100% !important;
    height: 270px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    display: none;
    margin-bottom: 30px;
  }
  .iframe {
    width: 100% !important;
    height: 270px !important;
  }
}

@media (max-width: 365px) {
  .vid-thumb {
    width: 150px !important;
    height: 85px !important;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .secondST {
    position: relative;
    width: 150px !important;
    height: 35px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
  }
  .secondST-description {
    position: absolute;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 16px !important;
    font-weight: 700 !important;
    top: -10% !important;
  }
  .secondST-text {
    position: absolute !important;
    font-size: 14px !important;
    font-weight: 500;
    color: #727272;
    top: -45% !important;
  }
  .custom-pagination .MuiPaginationItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(47, 150, 253, 1);
    font-size: 20px !important;
    font-weight: 600;
  }
}
