@media (max-width: 2560px) {
  .pho-config {
    position: relative;
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 1);
    gap: 15px;
    padding-bottom: 140px;
  }
}

.pho-config2 {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.logotext {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding-top: 190px;
}

.logotext span {
  font-size: 20px;
  font-weight: 500;
  color: rgba(60, 60, 60, 1);
  text-align: center;
}

.mainpho {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  z-index: 99;
}

.pho-btn {
  width: 100%;
  max-width: 280px;
  height: auto;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.pho-btn img {
  max-width: 280px;
  height: 190px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
}
.img-lenta-photo {
  position: absolute;
  top: -1.7% !important;
}

.btnFoot {
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(60, 60, 60, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.vector {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: end;
  padding-bottom: 18px;
}

.titCon {
  position: relative;
  width: 100%;
  height: 89px;
  border-top: 2px solid rgba(0, 0, 0, 1);
  border-bottom: 2px solid rgba(0, 0, 0, 1);
  padding-top: 7px;
}

.titCon span {
  font-size: 25px;
  font-weight: 500;
  color: rgba(114, 114, 114, 1);
}

.date,
.time,
.person,
.grade {
  font-size: 18px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.date span,
.time span,
.person span,
.grade span {
  font-size: 20px !important;
  font-weight: 700;
}

.titdate {
  width: 80%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.thumbImg {
  width: 100%;
  height: 485px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 28px;
  overflow: auto;
}

.main-thumb-image {
  width: 75%;
  height: auto;
}

.thumbnail-image {
  width: 199px;
  height: auto;
  cursor: pointer;
}

.thumbnail-con-image {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
  max-height: 400px;
}

.photo-respon-mob-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
@media (max-width: 1024px) {
  .mainpho {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .main-thumb-image {
    width: 90%;
    height: auto;
  }

  .display-none {
    display: none;
  }

  .display-none2 {
    display: none;
  }
  .date,
  .time,
  .person,
  .grade {
    font-size: 14px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .date span,
  .time span,
  .person span,
  .grade span {
    font-size: 16px !important;
  }

  .titdate {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
  }

  .thumbnail-con-image {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
  }

  .thumbnail-image {
    width: 150px;
    height: auto;
  }

  .thumbImg div {
    margin-left: 10px;
    overflow-x: scroll;
  }
  .date,
  .time,
  .person,
  .grade {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .img-lenta-photo {
    width: 100%;
    overflow: hidden;
    top: -0.5% !important;
  }

  .pho-btn {
    width: 180px !important;
    height: 165px;
  }

  .btnFoot {
    max-width: 240px;
    height: 40px;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .thumbImg img {
    width: 180px;
    height: 100px;
  }
  .photo-respon-mob-card {
    width: auto;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}
@media (max-width: 800px) {
  .pho-btn {
    width: 150px !important;
    height: 140px;
  }
}
@media (max-width: 680px) {
  .pho-btn {
    width: 130px !important;
    height: 120px;
  }

  .btnFoot {
    max-width: 200px;
    height: 35px;
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .pho-config {
    padding: 10px;
  }

  .logotext span {
    font-size: 18px;
  }

  .pho-btn {
    max-width: 200px;
  }

  .btnFoot {
    max-width: 200px;
    height: 35px;
    font-size: 16px;
  }

  .thumbImg img {
    width: 150px;
    height: 90px;
  }
  .date,
  .time,
  .person,
  .grade {
    font-size: 10px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
  .date span,
  .time span,
  .person span,
  .grade span {
    font-size: 12px !important;
  }
}

@media (max-width: 480px) {
  .pho-config {
    padding: 5px;
  }

  .logotext span {
    font-size: 16px;
  }

  .pho-btn {
    max-width: 180px;
  }

  .btnFoot {
    max-width: 180px;
    height: 30px;
    font-size: 14px;
  }

  .thumbImg img {
    width: 130px;
    height: 80px;
  }
}

@media (max-width: 450px) {
  .img-lenta-photo {
    width: 100%;
    top: 0 !important;
  }

  .display-none,
  .display-none2 {
    display: none;
  }
  .photo-respon-mob-card {
    width: 90%;
    height: 230px !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    align-items: flex-start;
  }

  .pho-btn img {
    max-width: 280px;
    height: 100% !important;
    width: 100%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
  }
  .mainpho {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 10px;
  }

  .pho-btn {
    width: 160px !important;
    height: 90px !important;
  }

  .pho-btn img {
    width: 100%;
  }
  .logotext {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding-top: 50px;
  }

  .style-css-box {
    width: 95% !important;
    height: 416px !important;
  }

  .titCon {
    position: relative;
    width: 100%;
    height: 60px;
    border-top: 2px solid rgba(0, 0, 0, 1);
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    padding-top: 7px;
  }
  .vector {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: end;
    padding-bottom: 18px;
  }

  .titCon span {
    font-size: 15px !important;
    font-weight: 500;
    color: rgba(114, 114, 114, 1);
  }

  .titdate {
    width: 90%;
    height: 35px !important;
    display: flex;
    margin-left: 0;
  }
  .date,
  .time,
  .person,
  .grade {
    font-size: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px !important;
  }
  .date span,
  .time span,
  .person span,
  .grade span {
    font-size: 9px !important;
  }

  .thumbImg {
    width: 100%;
    height: 250px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 28px;
    overflow: auto;
  }

  .main-thumb-image {
    width: 75%;
    height: 225px !important;
    object-fit: cover;
  }

  .thumbnail-image {
    width: 70px !important;
    height: 50px !important;
    object-fit: cover;
  }

  .thumbnail-con-image {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: scroll;
    max-height: 400px;
  }
}

@media (max-width: 400px) {
  .img-lenta-photo {
    position: absolute !important;
    width: 100%;
    top: 0 !important;
    overflow: hidden;
  }

  .logotext span {
    font-size: 15px !important;
  }
  .date,
  .time,
  .person,
  .grade {
    font-size: 7px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .date span,
  .time span,
  .person span,
  .grade span {
    font-size: 8px !important;
    font-weight: 700;
  }
}
