.conHead2 {
  position: relative;
  width: 100%;
  height: 1000px;
  background: rgba(156, 214, 245, 1);
}

@media (max-width: 1024px) {
  .conHead2 {
    position: relative;
    width: 100%;
    height: 700px;
    background: rgba(156, 214, 245, 1);
  }
}

.cloud-img-all-video {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: -11%;
}
.menu-rounded-icon-main-video {
  font-size: 40px !important;
}

.main-titLogo {
  width: 97%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
  padding-top: 10px;
}

.all-titLogo {
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 9px;
}
@media (max-width: 770px) {
  .menu-links {
    display: flex !important; /* Show links when the menu is open */
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 100;
  }

  .menu-links a {
    width: 210px;
    height: 70px;
    text-decoration: none;
    color: rgba(114, 114, 114, 1);
    font-size: 16px;
    font-weight: 600;
    padding: 20px 20px;
  }

  .menu-links a:hover,
  .menu-links a.active {
    color: #00d674;
  }

  .menu-icon {
    position: relative;
  }
}

@media (max-width: 500px) {
  .conHead2 {
    position: relative;
    width: 100%;
    height: 250px;
    background: rgba(156, 214, 245, 1);
  }
  .menu-rounded-icon-main-video {
    font-size: 30px !important;
  }
}
