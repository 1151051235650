#head-config {
  position: relative;
  width: 100%;
  height: 2000px;
  background: #ffffff;
  background: url("../../public/Icons/dec2.png") no-repeat/ center/cover;
}

#headCon2 {
  width: 100%;
  height: 1000px;
  background: rgba(255, 213, 65, 1);
  background-image: url("../../public/Icons/dec2.png"),
    url("../../public/Icons/cloud2.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

.coronaR {
  position: absolute;
  right: 0;
  top: 10%;
}

.coronaL {
  position: absolute;
}

.lens,
.len1,
.len2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.lens {
  flex-direction: column;
}

.len1 {
  height: 195px;
}

.len1 img {
  width: 324px;
  height: 184px;
  border-radius: 10px;
}

.len2 {
  height: 322px;
}

.len2 img {
  width: 579px;
  height: 312px;
  border-radius: 40px;
  border: 1px solid rgba(255, 122, 0, 1);
}

.Cinput,
.Cinput2 {
  position: relative;
  width: 295px;
  height: 63px;
  border-radius: 40px;
}

.Cinput input,
.Cinput2 input {
  width: 295px;
  height: 63px;
  border-radius: 40px;
  padding: 20px;
  font-size: large;
}

.HVideo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.HVideo2 {
  width: 60%;
  height: 657px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 1);
}

.iframe {
  width: 100%;
  height: 657px;
  z-index: 99;
}

.allVideos,
.con-vid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.con-vid {
  width: 1150px;
  gap: 20px;
}

.firstS {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 87px;
}

.firstS span {
  font-size: 20px;
  font-weight: 500;
  color: #3c3c3c;
  margin-left: 20px;
}

.secondST {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.secondST-text {
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  color: rgba(114, 114, 114, 1);
  top: -15%;
}

.secondST-description {
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  right: 5%;
  top: -40% !important;
}

.Cpag {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.vidTitle {
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.head-img-respon-phone {
  width: 70px;
}
.head-img-respon-phone2 {
  width: 250px;
}

.vidTitle span {
  width: 50%;
  height: 60px;
  border-radius: 40px;
  background: #ff7a00;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 9px;
  color: #ffffff;
  z-index: 99;
}
.custom-pagination2 .MuiPaginationItem-root {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(255, 255, 255, 1);
}

.custom-pagination2 .MuiPaginationItem-root:hover {
  background-color: none;
}

.custom-pagination2 .MuiPaginationItem-root.Mui-selected {
  background-color: rgba(255, 255, 255, 1);
  color: #ff7a00;
  font-size: 20px;
  font-weight: 600;
}

.custom-pagination2 .MuiPaginationItem-root.Mui-selected:hover {
  background-color: none;
}

.custom-pagination2 .MuiPaginationItem-previousNext,
.custom-pagination2 .MuiPaginationItem-ellipsis {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.custom-pagination2 .MuiPaginationItem-previousNext .MuiSvgIcon-root,
.custom-pagination2 .MuiPaginationItem-ellipsis .MuiSvgIcon-root {
  font-size: 40px;
  border-radius: 50%;
  color: #ff7a00;
  border: 1px solid rgba(217, 217, 217, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.custom-pagination2 .MuiPaginationItem-previousNext:hover,
.custom-pagination2 .MuiPaginationItem-ellipsis:hover {
  background-color: none;
}

@media (max-width: 1350px) {
  .vidTitle-text {
    width: 50%;
    height: 60px;
    font-size: 24px !important;
    border-radius: 40px;
    background: #ff7a00;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 9px;
    color: #ffffff;
    z-index: 99;
  }
}

@media (max-width: 1050px) {
  .vidTitle-text {
    font-size: 20px !important;
  }
}

@media (max-width: 1024px) {
  .vidTitle-text {
    width: 70%;
    font-size: 26px;
  }
  .vidTitle {
    padding-top: 100px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .con-vid {
    width: 95%;
  }

  .config-navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .HVideo2 {
    width: 80%;
    height: 550px;
    background: none;
    z-index: 99;
  }

  .iframe {
    height: 100%;
  }

  .vidTitle img {
    z-index: 99 !important;
  }

  #secondST-extra {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  #secondST-extra .secondST-text {
    top: -40% !important;
  }
  #secondST-extra .secondST-description {
    top: -10% !important;
    font-size: 16px !important;
    left: 40% !important;
  }
}

@media (max-width: 900px) {
  .vidTitle-text {
    font-size: 18px !important;
  }
}

@media (max-width: 880px) {
  .HVideo2 {
    width: 80% !important;
  }

  .iframe {
    width: 750px !important;
  }

  .vidTitle img {
    z-index: 99 !important;
  }
}

@media (max-width: 800px) {
  .vidTitle-text {
    width: 70% !important;
    font-size: 18px !important;
  }

  .secondST-description2 {
    font-size: 12px !important;
    top: -15% !important;
  }

  .secondST-text {
    font-size: 10px !important;
    top: -15% !important;
  }
}

@media (max-width: 768px) {
  .coronaR {
    top: 5%;
    width: 50px;
  }

  .coronaL {
    width: 50px;
  }

  .iframe {
    width: 600px !important;
    height: 420px !important;
  }

  .con-vid {
    padding: 0 10px;
  }

  .secondST-text {
    font-size: 12px;
  }

  .student-name {
    font-size: 16px;
  }

  .Cinput {
    width: 100%;
  }
}
@media (max-width: 700px) {
  #secondST-extra .secondST-text {
    top: -30% !important;
  }

  #secondST-extra .secondST-description {
    top: -5% !important;
    font-size: 14px !important;
    left: 37% !important;
  }
}

@media (max-width: 724px) {
  .vidTitle-text {
    font-size: 18px !important;
  }
}

@media (max-width: 650px) {
  .vidTitle-text {
    font-size: 17px !important;
  }
}

@media (max-width: 500px) {
  #head-config {
    position: relative;
    width: 100%;
    height: 1200px !important;
    background-image: none;
  }
  #headCon2 {
    width: 100%;
    height: 500px !important;
    background: rgba(255, 213, 65, 1);
    background-image: url("../../public/Icons/dec2.png"),
      url("../../public/Icons/cloud2.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .firstS {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 27px;
  }
  .head-img-respon-phone {
    width: 50px !important;
  }
  .head-img-respon-phone2 {
    width: 150px !important;
  }

  .firstS span {
    font-size: 14px;
    font-weight: 500;
    color: #3c3c3c;
    margin-left: 8px !important;
  }
  .vidTitle {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .vidTitle img {
    width: 150px;
  }

  .vidTitle span {
    width: 50%;
    height: 40px;
    border-radius: 40px;
    background: #ff7a00;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px !important;
    font-weight: 500;
    margin-bottom: 9px;
    color: #ffffff;
    z-index: 99;
  }
  .HVideo {
    width: 100%;
    height: 310px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }

  .HVideo2 {
    width: 95% !important;
    height: 310px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }

  .iframe {
    height: 300px !important;
    z-index: 99;
  }
  #secondST-extra {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  #secondST-extra .secondST-text {
    top: -30% !important;
  }
  #secondST-extra .secondST-description {
    top: 0 !important;
    font-size: 12px !important;
    left: 40% !important;
  }
}

@media (max-width: 415px) {
  #head-config {
    position: relative;
    width: 100%;
    height: 1500px !important;
    background-image: none;
  }
  #secondST-extra {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  #secondST-extra .secondST-text {
    top: -35% !important;
    font-size: 12px !important;
  }
  #secondST-extra .secondST-description {
    top: 0 !important;
    font-size: 12px !important;
    left: 40% !important;
  }
}
