.img-config {
  width: 100%;
  height: 1200px;
  background: rgba(255, 255, 255, 1);
  background-image: url("../../public/Icons/half.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 5%;
}

.mainImg {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../public/Icons/half2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-position-y: 100.2%;
  gap: 30px;
}

.tabButton {
  width: 166px;
  height: 50px;
  border-radius: 40px !important;
  font-size: 20px !important;
  font-weight: 600;
}

.btn-six-row {
  border-bottom: none;
  display: flex;
  justify-content: center;
  margin-bottom: 2;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px; /* Adjust height as necessary */
}

.carousel-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.prev-btn,
.next-btn {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #00d674;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  color: #00d674;
  padding: 10px;
  cursor: pointer;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.prev-btn:hover,
.next-btn:hover {
  color: #fff;
  background: #00d674;
  border: 1px solid #fff;
}

.pagination-dots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 13%;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  width: 30px;
  border-radius: 10px;
  background-color: rgba(255, 198, 0, 1); /* Yellow for active dot */
  border: none;
}

@media (max-width: 900px) {
  .carousel-container {
    position: relative;
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px; /* Adjust height as necessary */
  }

  .carousel-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .prev-btn,
  .next-btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #00d674;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    color: #00d674;
    padding: 10px;
    cursor: pointer;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabButton {
    width: 126px;
    height: 45px;
    border-radius: 30px !important;
    font-size: 18px !important;
    font-weight: 600;
  }
}

@media (max-width: 760px) {
  .img-config {
    width: 100%;
    height: 1100px !important;
    background: rgba(255, 255, 255, 1);
    background-image: url("../../public/Icons/half.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 5%;
  }
  .carousel-container {
    position: relative;
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px; /* Adjust height as necessary */
  }

  .carousel-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .prev-btn,
  .next-btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #00d674;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    color: #00d674;
    padding: 10px;
    cursor: pointer;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabButton {
    width: 100px;
    height: 40px;
    border-radius: 20px !important;
    font-size: 16px !important;
    font-weight: 600;
  }
  .pagination-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
  }
}

@media (max-width: 650px) {
  .img-config {
    width: 100%;
    height: 1000px !important;
    background: rgba(255, 255, 255, 1);
    background-image: url("../../public/Icons/half.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 5%;
  }
  .carousel-container {
    position: relative;
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px; /* Adjust height as necessary */
  }

  .carousel-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .prev-btn,
  .next-btn {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #00d674;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    color: #00d674;
    padding: 10px;
    cursor: pointer;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabButton {
    width: 110px !important;
    height: 35px !important;
    border-radius: 20px !important;
    font-size: 15px !important;
    font-weight: 600;
  }
  .pagination-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 20%;
  }
}

@media (max-width: 450px) {
  .btn-six-row {
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .img-dis-none-card {
    display: none;
  }
  .img-config {
    width: 100%;
    height: 700px !important;
    background: rgba(255, 255, 255, 1);
    background-image: url("../../public/Icons/half.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 5%;
  }
  .pagination-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 11% !important;
  }
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .dot.active {
    width: 20px;
    border-radius: 10px;
    background-color: rgba(255, 198, 0, 1); /* Yellow for active dot */
    border: none;
  }
  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }

  .carousel-slide img {
    width: 100%;
    height: 300px !important;
  }
}

@media (max-width: 400px) {
  .pagination-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 13% !important;
  }

  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }

  .carousel-slide img {
    width: 100%;
    height: 275px !important;
  }
  .tabButton {
    width: 90px !important;
    height: 33px !important;
    border-radius: 20px !important;
    font-size: 15px !important;
    font-weight: 600;
  }
}
