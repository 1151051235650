.com-config {
  position: relative;
  width: 100%;
  height: 1252px;
  background: rgba(255, 213, 65, 1);
  background-image: url("../../public/Icons/cloud2.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
}

.comMain {
  width: 100%;
  height: 100%;
  background-image: url("../../public/Icons/dec2.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnCom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.btnCom button {
  width: 280px;
  height: 70px;
  border: none;
  border-radius: 40px;
  background: rgba(255, 122, 0, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
  font-weight: 600;
}
#swiper-border-remove {
  border: 1px solid #ff7a00;
}

@media (max-width: 880px) {
  .respon-cen-p {
    position: absolute;
    top: -5% !important;
    left: 37%;
  }
  .com-config {
    position: relative;
    width: 100%;
    height: 1000px !important;
    background: rgba(255, 213, 65, 1);
    background-image: url("../../public/Icons/cloud2.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
  }
}

@media (max-width: 800px) {
  .respon-cen-p {
    position: absolute;
    top: 15% !important;
    left: 40%;
  }
}

@media (max-width: 700px) {
  .respon-cen-p {
    position: absolute;
    top: 12% !important;
    left: 40%;
  }
  .com-config {
    position: relative;
    width: 100%;
    height: 900px !important;
    background: rgba(255, 213, 65, 1);
    background-image: url("../../public/Icons/cloud2.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
  }
}

@media (max-width: 450px) {
  .btnCom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
  }

  .btnCom button {
    width: 170px !important;
    height: 40px;
    border: none;
    border-radius: 20px !important;
    background: rgba(255, 122, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
  }
  #swiper-border-remove {
    border: none;
  }
  .com-config {
    position: relative;
    width: 100%;
    height: 700px !important;
    background: rgba(255, 213, 65, 1);
    background-image: url("../../public/Icons/cloud2.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover 50%;
    padding-top: 50px;
  }
  .vidTitle2 {
    height: 80px !important;
    gap: 0 !important;
    padding-top: 20px;
  }
  .vidTitle2 img {
    width: 270px;
  }
}
