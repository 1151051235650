.config-footer {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 1);
}

.posTit {
  display: flex;
  flex-direction: row;
  gap: 86px;
}

.mainLogo {
  width: auto;
  height: 56px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.lastTit {
  width: 460px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-address-span span,
.footer-address-span a {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: rgba(140, 140, 140, 1);
  text-decoration: none;
}
.footer-about-project {
  font-size: 15px !important;
  color: rgba(140, 140, 140, 1);
}

@media (max-width: 750px) {
  .config-footer {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 1);
  }

  .posTit {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 7%;
  }
  .mainLogo img {
    width: 200px;
  }
  .lastTit {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 450px) {
  .config-footer {
    width: 100%;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    background: rgba(255, 255, 255, 1);
  }

  .posTit {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    gap: 10px !important;
    padding: 0px 7%;
  }
  .mainLogo img {
    width: 120px !important;
  }
  .lastTit {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mainLogo span {
    font-size: 12px !important;
  }
  .footer-address-span span,
  .footer-address-span a {
    font-size: 9.5px !important;
    font-weight: 500 !important;
    color: rgba(140, 140, 140, 1);
    text-decoration: none;
  }
  .footer-about-project {
    font-size: 12px !important;

    color: rgba(140, 140, 140, 1);
    word-spacing: 4px !important;
  }
  .lastTit span {
    font-size: 10px !important;
  }
}
/* @media (max-width: 400px) {
  .first-foot-title span {
    font-size: 10px !important;
    border: 2px solid yellow;
  }
} */
