/* Base styles (for desktop) */
.config-navbar {
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s;
}

.con-icon {
  cursor: pointer;
}

.con-link {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.main-link {
  text-decoration: none;
  color: rgba(114, 114, 114, 1);
  font-size: 16px;
  font-weight: 600;
}

.main-link.active {
  color: #00d674;
}

.main-link:active {
  color: rgba(0, 214, 116, 1);
}

.headPage {
  width: 100%;
  height: 730px;
  background-image: url("../../public/Image/main2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(116, 187, 81, 1);
}
.headPage2 {
  position: relative;
  width: 100%;
  height: 320px;
  background-color: rgba(116, 187, 81, 1);
  display: flex;
  justify-content: center;
}

.survey-test-span {
  font-size: 30px;
  color: #2c5d3d;
  font-weight: bold;
  text-align: center;
}

.card-for-link {
  position: absolute;
  max-width: 1000px;
  width: 777px;
  height: 300px;
  background: rgba(238, 238, 160, 255);
  border-radius: 10px;
  top: -45%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.btn-link-container-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  color: #2c5d3d;
  font-weight: 600;
  gap: 10px;
}

.btn-link-container-style span {
  text-align: center;
  line-height: 24px;
}
.btn-link-container-style button {
  width: 200px;
  height: 45px;
  border-radius: 15px;
  border: none;
  background: #d3542c;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  transition: transform 0.3s ease;
}
.btn-link-container-style button:hover {
  transform: scale(1.05);
}

.arrow {
  font-size: 24px;
  font-weight: 600;
}

.two-btn-style-link {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.animated-btn .arrow {
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.animated-btn:hover .arrow {
  animation: moveArrow 1s infinite;
}

@keyframes moveArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .config-navbar {
    height: 50px;
    padding: 0 10px;
    justify-content: space-between; /* Make space for mobile menu icon */
  }

  .two-btn-style-link {
    width: 85%;
  }
  .card-for-link {
    width: 641px;
    height: 250px;
    top: -40%;
  }

  .con-link {
    display: none;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    top: 50px;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    width: 200px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .con-link.open {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
  }

  .main-link {
    font-size: 14px;
  }

  .headPage {
    height: 600px;
  }
}

/* Additional media queries for smaller devices */
@media (max-width: 480px) {
  .config-navbar {
    height: 50px;
    padding: 0 15px;
    justify-content: space-between;
  }

  .con-link {
    width: 180px;
    padding: 15px;
  }

  .main-link {
    font-size: 13px;
  }

  .headPage {
    height: 500px;
  }
  .arrow {
    font-size: 20px;
    font-weight: 600;
  }

  .two-btn-style-link {
    width: 85%;
  }
  .card-for-link {
    width: 99.5%;
    height: 200px;
    top: -25%;
  }
  .btn-link-container-style button {
    width: 120px;
    height: 35px;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .animated-btn .arrow {
    display: none;
  }
  .survey-test-span {
    font-size: 18px;
  }

  .btn-link-container-style {
    font-size: 14px;
  }

  .btn-link-container-style span {
    line-height: 16px;
  }
}
