body {
  margin: 0;
  overflow-x: hidden;
}

.emo-container {
  position: relative;
  overflow-y: auto;
  height: 100vh;
}

.emo-container .top-gradient,
.emo-container .bottom-gradient {
  position: absolute;
  left: 0;
  width: 100%;
  height: 10%;
  pointer-events: none;
}

.emo-container .top-gradient {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
}

.emo-container .bottom-gradient {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}
@media (max-width: 2400px) and (min-width: 400px) {
  .emo-config {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 1);
    padding-bottom: 50px;
  }
}

.mainemo {
  max-width: 1300px;
  min-width: 400px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 25px;
  margin: 0 auto;
}

.con-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.con-img img {
  opacity: 70%;
}

.con-img img {
  aspect-ratio: 1 / 1;
  width: 170px;
}

.absolute-img {
  position: absolute;
}

@media (max-width: 992px) {
  .mainemo {
    width: 90%;
    gap: 30px;
  }

  .con-img {
    justify-content: space-between;
  }

  .con-img img {
    width: 140px;
  }
}

@media (max-width: 768px) {
  .mainemo {
    width: 95%;
    gap: 20px;
  }

  .con-img {
    justify-content: space-evenly;
  }

  .con-img img {
    width: 120px;
  }

  .absolute-img {
    position: relative;
    bottom: auto;
    left: auto;
  }
}

@media (max-width: 576px) {
  .mainemo {
    width: 100%;
    gap: 10px;
  }

  .con-img {
    justify-content: space-evenly;
  }

  .con-img img {
    width: 100px;
  }
}

@media (max-width: 450px) {
  .emo-config {
    display: none;
  }
}
