html,
body {
  overflow-x: hidden;
}
.config-navbar2 {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  z-index: 999;
}

.con-link {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.menu-icon {
  display: none;
}
.menu-rounded-icon-nav {
  font-size: 40px !important;
}

@media (min-width: 600px) and (max-width: 1062px) {
  .config-navbar2 {
    width: 100%;
    justify-content: space-between;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .con-link {
    display: none;
  }

  .menu-open {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 60px;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 100;
  }

  .menu-icon {
    display: flex;
  }

  .config-navbar2 {
    width: 100%;
    justify-content: space-between;
    padding: 20px;
  }
}

@media (min-width: 769px) {
  .con-link {
    display: flex;
  }

  .menu-icon {
    display: none;
  }
}

.main-link {
  text-decoration: none;
  color: rgba(114, 114, 114, 1);
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 10px;
  width: auto;
}

.main-link.active {
  color: #00d674;
}

@media (max-width: 450px) {
  .config-navbar2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-open {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 60px;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 100;
  }

  .menu-icon {
    display: flex;
  }
  .menu-rounded-icon-nav {
    font-size: 30px !important;
  }

  .config-navbar2 {
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
  .headPage {
    width: 100%;
    height: 400px !important;
    background-image: url("../../public/Image/main2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-color: rgba(116, 187, 81, 1);
  }
}

@media (max-width: 400px) {
  .headPage {
    width: 100%;
    height: 350px !important;
    background-image: url("../../public/Image/main2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-color: rgba(116, 187, 81, 1);
  }
}
