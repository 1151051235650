.swiper-custom-next.swiper-button-disabled,
.swiper-custom-prev.swiper-button-disabled {
  opacity: 0.2;
}

.swiper-custom-next {
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #2f96fd;
  right: 5%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.swiper-custom-prev {
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #2f96fd;
  left: 5%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.vidTitle2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 47px;
  gap: 13px;
}
.vidTitle2 span {
  font-size: 22px !important;
  font-weight: 500;
  margin-bottom: 9px;
  z-index: 99;
}

.vid-config {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 1500px;
  background-color: rgba(156, 214, 245, 1);
  background-image: url("../../public/Icons/cloud.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position-y: 67%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.vid-container {
  width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vid-thumb {
  overflow: hidden;
  display: block;
  width: auto;
}

.vid-thumb img {
  display: block;
}

@media (max-width: 1780px) {
  .swiper-custom-next {
    right: 0;
    bottom: 37%;
  }

  .swiper-custom-prev {
    left: 0;
    bottom: 37%;
  }
}
@media (max-width: 1600px) {
  .swiper-custom-next {
    right: -5% !important;
    bottom: 37%;
  }

  .swiper-custom-prev {
    left: -5% !important;
    bottom: 37%;
  }
}
@media (max-width: 1450px) {
  .swiper-custom-next {
    right: -10% !important;
    bottom: 37%;
  }

  .swiper-custom-prev {
    left: -10% !important;
    bottom: 37%;
  }
}
@media (max-width: 1350px) {
  .swiper-custom-next {
    right: -15% !important;
    bottom: 37%;
  }

  .swiper-custom-prev {
    left: -15% !important;
    bottom: 37%;
  }
  .vid-container {
    max-width: 900px;
    height: 550px;
  }

  .swiper-slide {
    max-width: 250px !important;
    height: 150px !important;
  }

  .thumbnail-video-image-youtube {
    width: 220px !important;
    height: 135px !important;
    border-radius: 10px 10px 0 0;
    background-size: cover;
  }

  .smallTitle {
    width: 220px !important;
    height: 50px !important;
    margin-bottom: 15px;
  }
  .swiper-container {
    height: 180px;
    margin-top: 20px;
    overflow: visible;
  }
}
@media (max-width: 1250px) {
  .swiper-custom-next {
    right: -10% !important;
    bottom: 37%;
  }
  .swiper-container {
    height: 180px;
    margin-top: 20px;
    overflow: visible;
  }

  .swiper-custom-prev {
    left: -10% !important;
    bottom: 37%;
  }
  .vid-container {
    max-width: 850px;
    height: 550px;
  }

  .swiper-slide {
    max-width: 250px !important;
    height: 150px !important;
  }

  .thumbnail-video-image-youtube {
    width: 210px !important;
    height: 130px !important;
    border-radius: 10px 10px 0 0;
    background-size: cover;
  }

  .smallTitle {
    width: 210px !important;
    height: 50px !important;
    margin-bottom: 10px;
  }
  .swiper-container {
    height: 180px;
    margin-top: 20px;
    overflow: visible;
  }
}
@media (max-width: 1150px) {
  .swiper-custom-next {
    right: -10% !important;
    bottom: 37%;
  }

  .swiper-custom-prev {
    left: -10% !important;
    bottom: 37%;
  }
  .vid-container {
    max-width: 770px;
    height: 550px;
  }

  .swiper-slide {
    max-width: 250px !important;
    height: 150px !important;
  }

  .thumbnail-video-image-youtube {
    width: 190px !important;
    height: 125px !important;
    border-radius: 10px 10px 0 0;
    background-size: cover;
  }

  .smallTitle {
    width: 190px !important;
    height: 45px !important;
    margin-bottom: 25px;
    border-radius: 0 0 10px 10px !important;
  }
  .swiper-container {
    height: 180px;
    margin-top: 20px;
    overflow: visible;
  }
}

.swiper-container {
  width: 960px;
  height: 180px;
  margin-top: 20px;
  overflow: visible;
}
.thumbVid {
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.vid-thumb img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.swiper-container-videos
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  position: relative;
  top: 0px;
}

.swiper-container-videos .swiper-slide a {
  text-decoration: none;
  font-size: 14px;
  color: #1f2f3c;
}

@media (max-width: 800px) {
  .swiper-container-videos .swiper-slide a {
    font-size: 11px;
    line-height: 14px;
  }
  .vid-config {
    position: relative;
    width: 100%;
    height: 900px;
    background-color: rgba(156, 214, 245, 1);
    background-image: url("../../public/Icons/cloud.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position-y: 80%;
  }
}

.swiper-container-videos .selected.swiper-slide a .desc {
  color: #721e2e;
  font-weight: bold;
}

.swiper-container-videos .selected {
  border-bottom: 1px solid #c4c4c4;
}

.swiper-container-videos .swiper-slide {
  max-width: auto;
  width: 208px;
  height: 145px;
}

.swiper-container-videos .swiper-slide a .desc {
  margin-top: 3px;
  margin-bottom: 3px;
}

.swiper-container-videos .swiper-slide img {
  height: auto;
  width: 100%;
}

.swiper-pagination-bullet-active {
  background: #b4234c;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.btnVid {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background: none;
}

.btnVid button {
  width: 280px;
  height: 70px;
  border-radius: 40px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(47, 150, 253, 1);
  font-size: 25px;
  font-weight: 600;
  border: none;
}

.smallTitle {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 230px;
  height: 45px;
  border-radius: 0px 0px 18px 18px;
  bottom: -25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.wrapPic {
  width: 100%;
  height: 600px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  overflow: hidden;
}

.wrap1 {
  width: 150%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wrap2 {
  width: 200%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inner-wrap {
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.wrap1 img {
  width: auto;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.wrap2 img {
  width: 332px;
  height: 191px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.main-iframe-video {
  max-width: 960px;
  width: 100%;
  height: 541px;
  position: relative;
}
.thumbnail-video-image-youtube {
  border-radius: 20px 20px 0px 0px;
  max-width: 230px;
  max-height: 170px;
  width: 230px;
  height: 130px;
}
.desc2 {
  font-size: 18px;
  font-weight: 600;
}
.prev-next-youtube-thumbnail {
  width: 90px !important;
  height: 90px !important;
}

@media (max-width: 1024px) {
  .vid-config {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 1400px !important;
    background-color: rgba(156, 214, 245, 1);
    background-image: url("../../public/Icons/cloud.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position-y: 65% !important;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .swiper-custom-next {
    right: -15% !important;
    bottom: 37%;
  }

  .swiper-custom-prev {
    left: -15% !important;
    bottom: 37%;
  }
  .vid-container {
    max-width: 680px;
    height: 550px;
  }

  .swiper-slide {
    max-width: 250px !important;
    height: 125px !important;
  }

  .thumbnail-video-image-youtube {
    width: 150px !important;
    height: 85px !important;
    border-radius: 10px 10px 0 0;
    background-size: cover;
  }

  .smallTitle {
    width: 150px !important;
    height: 37px !important;
    margin-bottom: 30px;
  }
  .swiper-container {
    margin-top: 20px;
    overflow: visible;
  }
  .desc2 {
    font-size: 16px;
    font-weight: 600;
  }
  .desc {
    font-size: 12px;
  }
  .vidTitle2 span {
    font-size: 18px !important;
    font-weight: 500;
    margin-bottom: 9px;
  }
  .vid-config {
    position: relative;
    width: 100%;
    background-color: rgba(156, 214, 245, 1);
    background-image: url("../../public/Icons/cloud.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position-y: 75%;
  }
  .prev-next-youtube-thumbnail {
    width: 70px !important;
    height: 70px !important;
  }
  .swiper-custom-next {
    position: absolute;
    width: 60px;
    height: 60px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #2f96fd;
    right: 5%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .swiper-custom-prev {
    position: absolute;
    width: 60px;
    height: 60px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #2f96fd;
    left: 5%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 880px) {
  .swiper-custom-next {
    right: -15% !important;
    bottom: 45%;
  }

  .swiper-custom-prev {
    left: -15% !important;
    bottom: 45%;
  }
  .vid-container {
    max-width: 600px !important;
    height: 450px;
  }

  .swiper-slide {
    max-width: 230px !important;
    height: 150px !important;
  }

  .thumbnail-video-image-youtube {
    width: 120px !important;
    height: 70px !important;
    border-radius: 10px 10px 0 0;
    background-size: cover;
  }

  .smallTitle {
    position: relative !important;
    width: 120px !important;
    height: 30px !important;
    top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .swiper-container {
    margin-top: 20px;
    overflow: visible;
  }
  .desc2 {
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    top: 14%;
    right: 40%;
  }
  .desc {
    position: absolute;
    font-size: 10px;
    top: 0;
  }
  .vidTitle2 span {
    font-size: 16px !important;
    font-weight: 500;
    margin-bottom: 9px;
    height: 45px !important;
  }
  .sun-dis-none {
    display: none;
  }
  .btnVid {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    background: none;
  }

  .btnVid button {
    width: 230px;
    height: 60px;
    border-radius: 40px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(47, 150, 253, 1);
    font-size: 22px;
    font-weight: 600;
    border: none;
  }
}

@media (max-width: 740px) {
  .vid-config {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 1250px !important;
    background-color: rgba(156, 214, 245, 1);
    background-image: url("../../public/Icons/cloud.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position-y: 60% !important;
  }
  .swiper-custom-next {
    right: -15% !important;
    bottom: 45%;
  }

  .swiper-custom-prev {
    left: -15% !important;
    bottom: 45%;
  }
  .vid-container {
    max-width: 520px !important;
    height: 350px;
  }

  .swiper-slide {
    max-width: 230px !important;
    height: 140px !important;
  }

  .thumbnail-video-image-youtube {
    width: 110px !important;
    height: 85px !important;
    border-radius: 10px 10px 0 0;
    background-size: cover;
  }

  .smallTitle {
    width: 110px !important;
    height: 27px !important;
    margin-bottom: 60px;
  }
  .swiper-container {
    margin-top: 20px;
    overflow: visible;
  }
  .desc2 {
    font-size: 10px;
    font-weight: 600;
  }
  .desc {
    font-size: 9px;
  }
  .vidTitle2 span {
    height: 45px !important;
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 9px;
  }
  .btnVid {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background: none;
  }

  .btnVid button {
    width: 220px;
    height: 50px;
    border-radius: 40px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(47, 150, 253, 1);
    font-size: 22px;
    font-weight: 600;
    border: none;
  }
  .wrapPic {
    width: 100%;
    height: 500px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    overflow: hidden;
  }

  .wrap1 {
    width: 150%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .wrap2 {
    width: 200%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .inner-wrap {
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .wrap1 img {
    width: auto;
    height: 120px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .wrap2 img {
    width: 332px;
    height: 191px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 500px) {
  .wrapPic {
    display: none;
  }
  .thumbnail-video-image-youtube {
    height: 85px !important;
  }
}
@media (max-width: 450px) {
  .main-iframe-video {
    width: 375px;
    height: 290px;
    position: relative;
  }
  .sun-dis-none {
    display: none;
  }
  .thumbnail-video-image-youtube {
    height: 65px !important;
  }

  .vid-config {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 700px !important;
    background: linear-gradient(to bottom, rgba(156, 214, 245, 1), #fff);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btnVid {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background: none;
  }

  .btnVid button {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(47, 150, 253, 1);
    font-size: 16px;
    font-weight: 600;
    border: none;
    margin-top: 30px !important;
  }
  .vidTitle2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    gap: 5px !important;
  }
  .vidTitle2 span {
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 0;
    z-index: 99;
  }
  .swiper-custom-next {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: none;
    border: none;
    right: 25% !important;
    bottom: -25%;
    box-shadow: none;
  }

  .swiper-custom-prev {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: none;
    border: none;
    left: 25% !important;
    bottom: -25%;
    box-shadow: none;
  }
  .prev-next-youtube-thumbnail {
    width: 50px !important;
    height: 50px !important;
  }
  .thumbVid {
    width: 350px;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .main-iframe-video {
    width: 355px;
    height: 270px;
    position: relative;
  }
}
